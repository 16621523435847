import * as THREE from 'three'

import React from 'react'
import { Plane } from './Plane'
import { useGLTF } from '@react-three/drei'

const Grid = () => {
	const d = { x: 12, y: 6, z: 24 }
	const p = [
		[0, -d.y / 2, 0],
		[0, d.y / 2, 0],
		[0, 0, -d.z / 2],
		[0, 0, d.z / 2],
		[-d.x / 2, 0, 0],
		[d.x / 2, 0, 0],
	]
	const dim = [24, 24, 12, 12, 24, 24]
	const r = [
		[0, 0, 0],
		[0, 0, 0],
		[Math.PI / 2, 0, 0],
		[Math.PI / 2, 0, 0],
		[0, 0, Math.PI / 2],
		[0, 0, Math.PI / 2],
	]
	console.log(dim[0])
	return (
		<mesh position={[0, 3, 0]}>
			{p.map((v, i) => (
				<gridHelper
					key={i}
					args={[dim[i], dim[i], 'silver', 'silver']}
					position={[v[0], v[1], v[2]]}
					rotation={[r[i][0], r[i][1], r[i][2]]}
				/>
			))}
		</mesh>
	)
}

export function Room(props) {
	const { nodes, materials } = useGLTF('/room_simple.glb')
	return (
		<group
			{...props}
			dispose={null}
		>
			<Plane
				rotation={[-Math.PI / 2, 0, 0]}
				position={[0, -0.05, 0]}
			/>
			<Plane
				rotation={[0, 0, 0]}
				position={[0, 0, -12]}
			/>
			<Plane
				rotation={[-Math.PI, 0, 0]}
				position={[0, 0, 12]}
			/>
			<Plane
				rotation={[0, -Math.PI / 2, 0]}
				position={[6, 0, 0]}
			/>
			<Plane
				rotation={[-Math.PI, Math.PI / 2, 0]}
				position={[-6, 0, 0]}
			/>
			<Grid />
			<mesh
				receiveShadow={true}
				position={[0, 3, 0]}
			>
				<boxGeometry args={[12.01, 6.01, 24.01]} />
				<meshBasicMaterial
					color='white'
					side={THREE.DoubleSide}
				/>
			</mesh>

			{/* <mesh castShadow receiveShadow geometry={nodes.door.geometry} material={materials.Door} /> */}
			{/* <mesh castShadow receiveShadow geometry={nodes.room.geometry} material={materials.Room} />
			<mesh castShadow receiveShadow geometry={nodes.screen.geometry} material={materials.Screen} />
			<mesh castShadow receiveShadow geometry={nodes.beam4.geometry} material={materials.Room} />
			<mesh castShadow receiveShadow geometry={nodes.beam3.geometry} material={materials.Room} />
			<mesh castShadow receiveShadow geometry={nodes.beam2.geometry} material={materials.Room} />
			<mesh castShadow receiveShadow geometry={nodes.beam5.geometry} material={materials.Room} />
			<mesh castShadow receiveShadow geometry={nodes.beam6.geometry} material={materials.Room} />
			<mesh castShadow receiveShadow geometry={nodes.beam7.geometry} material={materials.Room} />
			<mesh castShadow receiveShadow geometry={nodes.beam1.geometry} material={materials.Room} />
			<mesh castShadow receiveShadow geometry={nodes.room_base.geometry} material={materials['Room.001']} />
			<mesh castShadow receiveShadow geometry={nodes.floor.geometry} material={materials.Floor} /> */}
		</group>
	)
}

useGLTF.preload('/room_simple.glb')
