import React from 'react'
import { usePlane } from '@react-three/cannon'

export const Plane = (props) => {
	/** Plane collider */
	const [ref] = usePlane(() => ({
		rotation: props.rotation, //[-Math.PI / 2, 0, 0]
		position: props.position, //[0, -0.25, 0]
		//"skyblue"
		material: {
			friction: 0.1,
		},
	}))

	return (
		<>
			{/* <mesh
				ref={ref}
				receiveShadow={true}
				scale={[20, 20, 20]}
			>
				<planeGeometry />
				<meshPhongMaterial
					// color={props.color}
					receiveShadow
				/>
			</mesh> */}
		</>
	)
}
