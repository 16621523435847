import React, { useEffect, useRef, Suspense } from 'react'

// Physics
import { Physics } from '@react-three/cannon'

// Three
import { extend, useThree } from '@react-three/fiber'
import { PositionalAudio, useAspect, useVideoTexture } from '@react-three/drei'
import { PointerLockControls } from 'three/examples/jsm/controls/PointerLockControls'

// Prefabs
import { Player } from '../prefabs/Player'
// import { Skybox } from '../prefabs/Skybox'

import { Room } from '../prefabs/Room'
import { Speaker } from '../prefabs/Speaker'
import { FrontSide } from 'three'

extend({ PointerLockControls })

// const Loading = () => {
// 	return (

function Screen({ url }) {
	const texture = useVideoTexture(url)
	const size = useAspect(1, 1)
	// 4 × 3
	return (
		<mesh
			position={[-5.9, 3, 0]}
			rotation={[0, Math.PI / 2, 0]}
		>
			<planeGeometry
				attach='geometry'
				args={[6, 4.5]}
			/>
			<meshPhongMaterial
				map={texture}
				emissiveMap={texture}
				emissiveIntensity={0.2}
				emissive={0xffffff}
				side={FrontSide}
			/>
		</mesh>
	)
}

export const DefaultScene = ({ ready }) => {
	const { camera, gl } = useThree()
	const controls = useRef()

	useEffect(() => {
		camera.layers.enable(0)
		camera.layers.enable(1)
	}, [camera])

	useEffect(() => {
		const handleFocus = () => {
			controls.current.lock()
		}
		document.addEventListener('click', handleFocus)

		return () => {
			document.removeEventListener('click', handleFocus)
		}
	}, [gl])

	console.log(ready)

	return (
		<>
			<fog
				attach='fog'
				color='lightgray'
				near={0.1}
				far={25}
			/>
			{/** Skybox */}
			{/* <Skybox /> */}
			{/** Pointer lock */}
			<pointerLockControls
				ref={controls}
				args={[camera, gl.domElement]}
			/>
			{/** Lighting */}
			<directionalLight
				position={[0, 2, 0]}
				intensity={0.5}
				castShadow
			/>
			<pointLight
				position={[0, 2, -3]}
				intensity={0.4}
				castShadow
			/>
			<pointLight
				position={[0, 2, 3]}
				intensity={0.4}
				castShadow
			/>
			<ambientLight intensity={0.3} />
			{/** Physic objects */}
			<Physics
				gravity={[0, -9, 0]}
				tolerance={0}
				iterations={50}
				broadphase={'SAP'}
			>
				{/** Player */}
				<Player />
				{/** Floor */}
				<Room />
				<Speaker
					position={[-5.5, 0, 7.5]}
					url={'s1.wav'}
				/>
				<Speaker
					position={[-5.5, 0, 4.5]}
					url={'s2.wav'}
				/>
				<Speaker
					position={[-5.5, 0, 1.5]}
					url={'s3.wav'}
				/>
				<Speaker
					position={[-5.5, 0, -1.5]}
					url={'s4.wav'}
				/>
				<Speaker
					position={[-5.5, 0, -4.5]}
					url={'s5.wav'}
				/>
				<Speaker
					position={[-5.5, 0, -7.5]}
					url={'s6.wav'}
				/>
				<Screen url='test.mov' />
			</Physics>
		</>
	)
}
